import Footer from "../../components/Footer";
import Header from "../../components/Header";
import FaqContent from "../../components/FaqContent";

function Faq() {
   
    return (
        <>
            <FaqContent/>
            <Footer/>
        </>
    );
}

export default Faq;